import React from 'react';

import { HttpError } from '@appCore/services/http/http';
import { reportError } from '@appCore/services/logger';

/**
 * The intent of this class to standardize the "reporting behavior" (sending a log somewhere) of any error
 * boundaries we use throughout our front-end properties. It expects that the primary http client in use
 * is the client established in @appCore/services/http and that the desired reporting functionality
 * matches the tools exposed in @appCore/services/logger.
 *
 * In general, if you have an Error Boundary that you're using in app-next/app-public, it should extend from this
 * class and NOT OVERRIDE componentDidCatch.
 */
class ReportingErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidCatch(error, errorInfo) {
        // Only report errors unrelated to the http client module, which handles reporting already
        if (!(error instanceof HttpError)) {
            let reportedError;
            if (error instanceof Error) {
                // If we have an actual error, send the stacktrace along with the wrapper. This is ideal and should
                // tell you exactly where the error occurred
                reportedError = new Error('React ErrorBoundary intercepted error', { cause: error });
                reportedError.stack = error.stack;
            } else {
                // If we don't have an error, use the componentStack as a "next best" alternative and change the
                // message to inform about the situation. This is less ideal and should tell you the location of the
                // closest-wrapping (nearest parent) component in the tree.
                reportedError = new Error('React ErrorBoundary intercepted error [tracing component stack]', {
                    cause: error
                });
                reportedError.stack = errorInfo.componentStack;
            }
            reportError(reportedError);
        }
    }
}

export default ReportingErrorBoundary;
