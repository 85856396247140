// browser polyfills
import 'core-js/stable';

import { FIELD_LEVEL_WINDOW_KEY, BOOTSTRAP_WINDOW_KEY } from '@common/constants';

import { init as performanceInit } from './telemetry/performance';
import { getRawParsedResult } from './userAgent';
import supportedBrowsers from './browsers';
import { httpFallbackWarnLogger } from './logging/fallback';
import { logEvent, logEventLegacy, AnalyticsTypeEnum } from './telemetry/NatsAnalytics';
import {
    logInfo,
    logWarning,
    logCritical,
    reportError,
    LogPriorityEnum,
    addCurrentLocationToBreadCrumb
} from './logging/NatsLogger';

const Bootstrap = {
    logEvent,
    logEventLegacy,
    logInfo,
    logWarning,
    logCritical,
    reportError,
    addCurrentLocationToBreadCrumb,
    LogPriorityEnum,
    AnalyticsTypeEnum
};

window[FIELD_LEVEL_WINDOW_KEY][BOOTSTRAP_WINDOW_KEY] = Bootstrap;

function browserErrorHandler(event) {
    const error = event.error;

    // React Error Boundaries have a peculiar behavior in that any errors that they intercept/catch will
    // be bubbled up to the "global" (window) event handler in Development mode, but not in Production. In order
    // to maintain consistency between dev/prod with regard to error reporting, we're detecting these specific errors,
    // and opting not to report them here.

    // Docs: https://react.dev/reference/react/Component#componentdidcatch-caveats
    // Github discussion: https://github.com/facebook/react/issues/10474
    if (error.stack && error.stack.includes('invokeGuardedCallbackDev')) {
        return;
    }

    reportError(error);
}

function browserRejectionHandler(event) {
    const reason = event.reason;
    if (reason instanceof Error) {
        reportError(reason);
    } else {
        const e = new Error(`Unknown unhandled promise rejection [${JSON.stringify(reason)}]`);
        e.stack = event.promise?._stack;
        reportError(e);
    }
}

function init() {
    // Naively add a local callstack to every Promise. It doesn't provide a deep trace of nested promises,
    // but will give a small clue as to where the rejection is coming from. We should only enable this
    // when we're trying to debug tough-to-track-down promise rejections.
    (function addStackToNativePromise() {
        try {
            const nativePromise = window.Promise;

            window.Promise = function Promise(...args) {
                const p = new nativePromise(...args);
                p._stack = new Error().stack;
                return p;
            };

            Object.getOwnPropertyNames(nativePromise).forEach(pn => {
                const descriptor = Object.getOwnPropertyDescriptor(window.Promise, pn);
                try {
                    if (!descriptor || descriptor.writable) {
                        window.Promise[pn] = nativePromise[pn];
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
        } catch (e) {
            console.error(new Error(`Unable to instrument native Promise with stacktrace`, { cause: e }));
        }
    })();

    if (!supportedBrowsers.test(navigator.userAgent)) {
        const parsed = getRawParsedResult();
        httpFallbackWarnLogger(`Detected an unsupported browser\n\n${JSON.stringify(parsed, null, 2)}`);
    }

    // Listen to uncaught errors
    window.addEventListener('error', browserErrorHandler);
    // Listen to uncaught promises rejections
    window.addEventListener('unhandledrejection', browserRejectionHandler);
}

init();
performanceInit();
