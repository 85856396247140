import React from 'react';
import { Card, Title } from '@fieldlevel/playbook';

import ReportingErrorBoundary from '@appCore/components/ReportingErrorBoundary';
import { HttpError } from '@appCore/services/http/http';

import ContentWrapper from './ContentWrapper';

class LayoutErrorBoundary extends ReportingErrorBoundary {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, is404: error.status === 404, isDataLoadingError: error instanceof HttpError };
    }

    render() {
        if (this.state.hasError) {
            const { titleText, descriptionText } = (() => {
                if (this.state.isDataLoadingError) {
                    return {
                        titleText: 'There was an error loading content for this page.',
                        descriptionText: 'Our team has been notified and is working toward a resolution.'
                    };
                }
                if (this.state.is404) {
                    return {
                        titleText: 'Page not found.',
                        descriptionText:
                            'Please check the url in your address bar for accuracy or use the navigation menu to browse the site.'
                    };
                }
                return {
                    titleText: `We're sorry, something went wrong.`,
                    descriptionText: 'Our team has been notified and is working toward a resolution.'
                };
            })();

            return (
                <ContentWrapper>
                    <Card>
                        <div className="min-h-[450px] pt-10 text-center">
                            <Title as="h1" size="large">
                                {titleText}
                            </Title>
                            <p className="mt-4 sm:mt-8">{descriptionText}</p>
                        </div>
                    </Card>
                </ContentWrapper>
            );
        }

        return this.props.children;
    }
}

export default LayoutErrorBoundary;
